<template>
    <div v-if="$route.meta.isLayout == 'main'"
         id="app">
        <div :class="{live: envCheck}"
             class="envBox">
            <p>
                {{ envNode }}
            </p>
        </div>

        <HeaderView class="menuWrap"></HeaderView>

        <Mainvisual id="top" ref="mainView"
                    ></Mainvisual>
        <!-- News Board -->
        <div id="news" class="news" ref="newsRef">

            <!--<img class="backImg" :src="`${rootPath}/images/bg_news.png`" alt="배경이미지" />-->

            <div class="newsBoard">

                <img :src="`./images/${$store.state.lang}/${getIsResponse}/title_news.png`"
                     :alt="`NotFound : ${$store.state.lang}/${getIsResponse}/title_news.png`" />

                <div class="newsTab">

                    <NewsCategory></NewsCategory>

                    <router-view></router-view>
                </div>
            </div>
        </div>

        <PromotionVideo id="mov"></PromotionVideo>

        <Story id="story"></Story>

        <Community id="line" ref="move1"></Community>
        <!-- Footer -->
        <Footer id="system"></Footer>

    </div>

    <div v-else-if="$route.meta.isLayout == 'board'"
         id="app">
        <div class="newsTab" >

            <NewsCategory></NewsCategory>

            <router-view></router-view>

        </div>
    </div>

    <div v-else-if="$route.meta.isLayout == 'mail'"
           id="app">

        <v-app>
            <v-main>
                 <router-view></router-view>
            </v-main>
        </v-app>

        <Footer id="system"></Footer>

    </div>

    <div v-else
         id="app">

        <router-view></router-view>

    </div>

</template>

<script>

    import HeaderView from '@/components/HeaderView.vue'
    import Mainvisual from '@/components/Mainvisual.vue'
    import PromotionVideo from '@/components/PromotionVideo.vue'
    import Story from '@/components/Story.vue'
    import Community from '@/components/Community.vue'
    import Footer from '@/components/FooterView.vue'
    import NewsCategory from '@/components/news/NewsCategory.vue'

    export default {
        name: 'App',
        components: {
            HeaderView,
            Mainvisual,
            PromotionVideo,
            Story,
            Community,
            NewsCategory,
            Footer,
        },
        data() {
            return {
                envCheck: false,
                envNode: process.env.NODE_ENV,
                evtApiServer: process.env.VUE_APP_API_SERVER,
                eventKey: process.env.VUE_APP_CONF_EVENT_KEY,
              
            }
        },
        created() {
           
            this.envCheck = process.env.NODE_ENV == 'production'

            this.fnPreCheckEventInfo()
        },
        computed: {
            getLang() {
                return this.$store.state.lang
            },
            getIsResponse() {
                // todo isResponse 수정 필요
                return this.$store.state.isResponse || 'pc'
            },
            rootPath() {
                return this.$store.state.rootPath
            },
        },
        methods: {
            fnPreCheckEventInfo() {
                let eventOptions = {
                    headers: { 'Content-Type': 'application/json' },
                    url: this.evtApiServer + '/event/eventInfo?eventKey=' + this.eventKey,
                    method: 'get',
                }
                this.$axios(eventOptions)
                    .then((res) => {
                        if (res.data.EventUID > 0) {
                            let Now = (new Date()).getTime();
                            let StartTime = new Date(res.data.SiteOpenTime + 'Z');
                            let EndTime = new Date(res.data.SiteCloseTime + 'Z');

                            let RegEndTime = new Date(res.data.RegEndTime + 'Z').getTime()

                            this.$store.state.isRegEndTime = RegEndTime < Now
                            
                            if (StartTime.getTime() <= Now && EndTime.getTime() > Now) {

                                this.$store.state.isEventTime = true

                            }
                        }
                    })
                    .catch((err) => {
                        console.error(`${err} (${err.status})\n\nMessage: ${err.responseText}`);
                        location.replace('https://www.grandchase.net/');
                    })
            },
        }
       
    }
</script>

<style lang="scss">
    @import '../src/assets/scss/_variables.scss';

    $width: 100%;
    .v-application--wrap {
        min-height: auto;
    }

    .envBox {
        position: fixed;
        width: 100%;
        height: 20px;
        background: red;
        z-index: 11;
        text-align: center;
        color: #fff;

        &.live {
            display: none;
        }
    }
    * {
        margin: 0;
        padding: 0;
        font-family: 'Noto Sans', sans-serif;
    }
    img {
        vertical-align: middle;
    }
    a {
        color: #fff;
        text-decoration: none;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none;
    }
    body, html {
        transition: all 0.4s;
    }
    .menuWrap {
        background: rgba(0,0,0,0.5);
        padding: 12px 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 50px;
    }
    #app {
        overflow: hidden;
    }
    .news {
        position: relative;
        width: 100%;
        background: url('/public/images/bg_news.png') repeat-y 0 0/100% #80c0f3;

        &:before {
            display: block;
            clear: both;
            content: '';
            width: $width;
            background: linear-gradient(to top,#60a4da, #80c0f3 80%, transparent);
            position: absolute;
            top: 50px;
            bottom: 0;
            left: 0;
            z-index: 0;
        }

        .newsBoard {
            /*      
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            */
            width: 1200px;
            margin: 0 auto;
            padding: 10px 0 50px;

            > img {
                position: relative;
                z-index: 3;
            }

            .newsTab {
                width: 100%;
                position: relative;
            }
        }
    }


    @media all and (max-width: 1200px) {
        .news {
            .newsBoard {
                width: 100%;
                padding: 10px 10px;

                > img {
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                }

                .newsTab {
                }
            }

            background: url('/public/images/bg_news.png') no-repeat;
        }   
    }

    @media all and (max-width: 640px ) {
        .menuWrap {
            height: 40px;
        }
    }
</style>
